/*
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/typography'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import {  ClipboardDocumentCheckIcon } from '@heroicons/react/20/solid'

export default function TepelneCerpadlo() {
    return (
        <div className="overflow-hidden bg-white">
            <div className="relative mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:px-8">
                <div className="absolute top-0 bottom-0 left-3/4 hidden w-screen bg-gray-50 lg:block" />
                <div className="mx-auto max-w-prose text-base lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-8">
                    <div>
                        <h2 className="text-lg font-semibold text-topklimatBlue">O tepelném čerpadlu</h2>
                        <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">Jak funguje tepelné čerpadlo?</h3>
                    </div>
                </div>
                <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
                    <div className="relative lg:col-start-2 lg:row-start-1">
                        <svg
                            className="absolute top-0 right-0 -mt-20 -mr-20 hidden lg:block"
                            width={404}
                            height={384}
                            fill="none"
                            viewBox="0 0 404 384"
                            aria-hidden="true"
                        >
                            <defs>
                                <pattern
                                    id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                                    x={0}
                                    y={0}
                                    width={20}
                                    height={20}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width={404} height={384} fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
                        </svg>
                        <div className="relative mx-auto max-w-prose text-base lg:max-w-none">
                            <figure>
                                <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                                    <img
                                        className="rounded-lg object-cover object-center shadow-lg"
                                        src="https://www.vaillant.cz/media-master/global-media/vaillant/product-pictures/arotherm-plus-2019/b2c/vai-12-09-197825-02-1594608-format-5-6@570@retina.jpg"
                                        alt="Whitney leaning against a railing on a downtown street"
                                        width={1184}
                                        height={1376}
                                    />
                                </div>
                                <figcaption className="mt-3 flex text-sm text-gray-500">
                                    <ClipboardDocumentCheckIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                                    <span className="ml-2">Text převzatý z <a href="https://www.cerpadla-ivt.cz/cz/princip-tepelnych-cerpadel" className="underline text-gray-700">Čerpadla Ivt</a></span>
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                    <div className="mt-8 lg:mt-0">
                        <div className="mx-auto max-w-prose text-base lg:max-w-none">
                            <p className="text-lg text-gray-500">
                                Princip tepelného čerpadla lze přirovnat k principu chladničky, která odebírá teplo potravinám a tímto odebraným teplem v podstatě vytápí místnost, kde je umístěna.
                                V případě tepelného čerpadla se teplo získává z okolního prostředí, kde je ho na rozdíl od potravin v ledničce neomezené množství.
                            </p>
                        </div>
                        <div className="prose prose-indigo mx-auto mt-5 text-gray-500 lg:col-start-1 lg:row-start-1 lg:max-w-none">
                            <ul role="list">
                                <li>Tepelné čerpadlo odnímá teplo z okolí vytápěného objektu (vzduchu, země nebo vody) a převádí ho na vyšší teplotní hladinu použitelnou pro vytápění a ohřev teplé vody.  </li>
                                <li>Převod tepla na vyšší teplotní hladinu je možný díky stlačení par chladiva v kompresoru, při kterém dojde k jeho zahřátí. Je to stejný princip, jako když pumpičkou foukáte kolo. Vzduch i pumpička se při stlačování vzduchu výrazně zahřejí.</li>
                                <li>Tepelné čerpadlo dokáže tohoto principu skvěle využít a získat tak zdarma teplo z okolí. Jak ale získat teplo z místa, kde je zima?  Ještě více to místo ochladit a tím mu teplo odebrat. Díky tomuto principu může tepelné čerpadlo získat teplo i ze vzduchu, který má teplotu - 20°C. Získávání tepla z takto nízkých teplot je o něco méně efektivní, ale pořád ještě výhodné. </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
