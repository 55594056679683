import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
    Bars3Icon,
    XMarkIcon,
} from '@heroicons/react/24/outline'

const navigation = [
    { name: 'Zpět na hlavní stránku', to: 'https://www.topklimat.cz' },
]
export default function Menugetbacktohomepage() {
    return (

        <Popover className=" fixed w-full pt-0 mt-0 z-[1000] top-0  md:bg-white  md:bg-opacity-80 backdrop-darken md:backdrop-blur-xl md:rounded-b-lg">
            <div className="flex items-center justify-between px-4 pt-4 pb-2 sm:px-6 md:justify-start md:space-x-10">
                <div>
                    <a href="https://www.deleno.cz" className="flex flex-col">
                        <div className="w-auto font-black">topklimat</div>
                    </a>
                </div>
                <div className="-my-2 -mr-2 md:hidden">
                    <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Open menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                </div>
                <div className="hidden md:flex md:flex-1 md:items-center md:justify-between">
                    <Popover.Group as="nav" className="flex space-x-10">
                        {navigation.map((link) => (
                            <a key={link.name} href={link.to} className="text-base font-regular text-gray-600 hover:text-indigo-50">
                                {link.name}
                            </a>
                        ))}
                    </Popover.Group>
                </div>
            </div>

            <Transition
                as={Fragment}
                enter="duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <Popover.Panel focus className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden">
                    <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="px-5 pt-5 pb-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <div className="h-8">Děleno</div>
                                </div>
                                <div className="-mr-2">
                                    <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                        <span className="sr-only">Close menu</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </Popover.Button>
                                </div>
                            </div>

                        </div>
                        <div className="py-6 px-5">
                            <div className="grid grid-cols-2 gap-4">
                                {navigation.map((link) => (
                                    <a key={link.name} href={link.to} className="text-base font-medium text-gray-600 hover:text-indigo-50">
                                        {link.name}
                                    </a>
                                ))}
                            </div>

                        </div>
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    )
}
