import Landing from "./components/landing";
import Carousalprodukt from "./components/carousalprodukt";
import Dalsisluzby from "./components/dalsisluzby";
import Logodump from "./components/logodump";
import Onasvic from "./components/onasvic";
import Kontaktovat from "./components/kontaktovat";
import Divider from "./components/divider";
import Zelenausporam from "./components/zelenausporam";
import Menubar from "./components/menu";
import Footer from "./components/footer";
import TepelneCerpadlo from "./components/tepelneCerpadlo";
import Klimatizace from "./components/Klimatizace";

export default function Home(){
    return(
        <div className="scroll-smooth">
            <Menubar />
            <Landing />
            <Carousalprodukt />
            <Divider text={"Partneři"} id="partneři"/>
            <Logodump />
            <Divider text={"O nás"} id="onas"/>
            <Onasvic />
            <Divider text={"Služby"} id="sluzby"/>
            <Dalsisluzby />
            <Divider text={"Zelená úsporám"} id="zelenausporam"/>
            <Zelenausporam />
                <Divider text={"Tepelné čerpadlo"} id="tepelnecerpadlo"/>
                <TepelneCerpadlo />
            <Divider text={"Klimatizace"} id="klimatizace"/>
            <Klimatizace />
            <Divider text={"Kontaktovat"} id="kontakt"/>
            <Kontaktovat />
            <Footer />
        </div>
    )
}