import zelenausporamlogo from '../images/zelena-usporam-doplnkova-varianta-lg-ng.png'
import sfzplogo from '../images/sfzplogo.png'
export default function Zelenausporam() {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                <div className="lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
                    <div>
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                            Pomůžeme vám získat dotaci na rekonstrukci
                        </h2>
                        <p className="mt-3 max-w-3xl text-lg text-gray-500">
                            Díky nízkým emisím můžete od státu na tepelné čerpadlo dostat dotaci. Naše společnost má s celým procesem bohaté zkušenosti a bude vám nápomocná při celém procesu.
                        </p>
                        <div className="mt-8 sm:flex">
                            <div className="rounded-md shadow">
                                <a
                                    href="https://novazelenausporam.cz/dotacni-kalkulacka"
                                    className="flex items-center justify-center rounded-md border border-transparent bg-[#0096C8] px-5 py-3 text-base font-medium text-white hover:bg-indigo-700"
                                >
                                    Dotační kalkulačka
                                </a>
                            </div>
                            <div className="mt-3 sm:mt-0 sm:ml-3">
                                <a
                                    href="#kontakt"
                                    className="flex items-center justify-center rounded-md border border-transparent bg-orange-500 px-5 py-3 text-base font-medium text-white hover:bg-orange-400"
                                >
                                    Napište nám
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="mt-8 grid grid-cols-1 gap-0.5 md:grid-cols-1 lg:mt-0 lg:grid-cols-1 " >
                        <div className="flex justify-start py-8 ">
                            <img
                                className="max-h-12"
                                src={sfzplogo}
                                alt="Workcation"
                            />
                        </div>
                        <div className="flex justify-start py-8 ">
                            <img
                                className="max-h-12"
                                src={zelenausporamlogo}
                                alt="Workcation"
                            />
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}
