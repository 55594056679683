import { CheckIcon } from '@heroicons/react/24/outline'

const features = [
    {
        name: 'monitoring teplot ve výrobních prostorech',
    },
    {
        name: 'datalogery pro záznam teplot',
    },
    {
        name: 'ohřev bazénů pomocí tepelného čerpadla',
    },
    {
        name: 'technologie pro přípravny potravin – 12 °C',
    },
    {
        name: 'chladící boxy všech rozměrů',
    },
    {
        name: 'mrazící boxy',
    },
    {
        name: 'klimatizace rezidenční',
    },
    {
        name: 'tepelná čerpadla pro RD, BD a komerční objekty',
    },
    {
        name: 'VRF systémy',
    },
    {
        name: 'klimatizace komerční',
    },
    {
        name: 'větrání, ventilace a rekuperace',
    },
    {
        name: 'solární panely',
    },
    {
        name: 'zchlazovny tepelně upravovaných výrobků',
    },
]

export default function Dalsisluzby() {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
                <div className="mx-auto max-w-3xl text-center">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900">Všestranné řešení pro Vaši stavbu</h2>
                    <p className="mt-4 text-lg text-gray-500">
                        Výpis služeb je neúplný. Pokud potřebujete řešení mimo rámec běžných služeb, kontaktujte nás.
                    </p>
                </div>
                <dl className="mt-12 space-y-10 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-8 sm:space-y-0 lg:grid-cols-4 lg:gap-x-8">
                    {features.map((feature) => (
                        <div key={feature.name} className="relative">
                            <dt>
                                <CheckIcon className="absolute h-6 w-6 text-orange-500 font-black" aria-hidden="true" />
                                <p className="ml-9 text-lg font-medium leading-6 text-gray-900">{feature.name}</p>
                            </dt>
                        </div>
                    ))}
                </dl>
            </div>
        </div>
    )
}
