import { Routes, Route } from "react-router-dom";
import Home from "./home";
import Error404 from "./components/404";
import ZasadyDat from "./components/zasadyochranyou";
function App() {
  return (
      <div className="overflow-clip max-w-screen">
        <Routes>
          <Route path="" element={ <Home /> } />
            <Route path="vasedatanamnejsouukradena" element={ <ZasadyDat /> } />
            <Route path="*" element={ <Error404 /> } />
        </Routes>
      </div>
  );
}

export default App;
