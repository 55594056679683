import Tepelnecerpadlo from "../images/threeproducts/tepelnecerpadlo.webp";
import Mrazicibox from "../images/threeproducts/mrazicibox.webp";
import Klimatizace from "../images/threeproducts/klimatizace.webp"

import {useState} from "react";

export default function Carousalprodukt(){
    const [itemId, setItemId] = useState(0);
    const items = [
        {id:0,title:"Tepelná čerpadla",image:Tepelnecerpadlo,description:"Tepelná čerpadla Vám poskytnou bezkonkurenční úsporu energií. Zajišťujeme montáž a servis čerpadel všech značek. "},
        {id:1,title:"Klimatizace",image:Klimatizace,description:"Pro snesitelnější vedra vytváříme ta nejlepší chladící řešení pro rodinné domy i komerční objekty.  "},
        {id:2,title:"Mrazící boxy",image:Mrazicibox,description:"Jsme nejlepší na trhu v individuálních řešeních vysoce náročných mrazících boxů. Třeba na krevní plasmu."},

    ]
    const menuItems = [
    {title:"Tepelná čerpadla", id:0},
    {title:"Klimatizace", id:1},
    {title:"Mrazící boxy", id:2},

    ]
    let item;
    let menu;
    function isCurrentItem(id,title){
        if(itemId == id){
            return <div className="w-1/3 text-center text-white flex flex-col items-center bg-orange-500 rounded-md p-1 cursor-pointer">
                <div className="w-full ">{title}</div>
                <div className="w-2/3 w-full h-[3px] rounded-full bg-white"></div>
            </div>
        }else {
            return <div className="w-1/3 text-center font-thin flex flex-col items-center p-1 h-full hover:bg-gray-100 hover:rounded-md cursor-pointer duration-100" onClick={() => setItemId(id)}>
                <div className="w-full">{title}</div>
                <div className="w-2/3 w-full  bg-gray-300 h-[3px] rounded-full"></div>
            </div>
        }
    }

    let id;
    let title;
    const menu3 =<>
        <div className="flex flex-row hidden md:flex w-[60vw] text-xl text-gray-400 p-1 font-regular rounded-md drop-shadow-sm bg-gray-50">
            {menuItems.map((menu) => (
                isCurrentItem(id=menu.id, title=menu.title)
            ))}
        </div>
        <div className="flex flex-row md:hidden md:flex w-[60vw] text-xl text-gray-400 p-1 font-regular rounded-md drop-shadow-sm bg-gray-50">
            {menuItems.map((menu) => (
                isCurrentItem(id=menu.id, title=(menu.id+1))
            ))}
        </div>
    </>;
    const productGeneral = <>
        <div className="bg-white">
            <div className="mx-auto max-w-7xl w-full py-16 px-4 sm:px-6 lg:px-8">
                <div className="overflow-hidden rounded-lg bg-topklimatBlue shadow-xl lg:grid lg:grid-cols-2 lg:gap-4">
                    <div className="px-6 pt-10 pb-12 sm:px-16 sm:pt-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
                        <div className="lg:self-center">
                            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                                {items[itemId].title}
                            </h2>
                            <p className="mt-4 text-lg leading-6 text-gray-200">
                                {items[itemId].description}
                            </p>
                            <a
                                href="#kontakt"
                                className="mt-8 inline-flex items-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-topklimatBlue shadow hover:bg-indigo-700 hover:text-white"
                            >
                                Zjistit více
                            </a>
                        </div>
                    </div>
                    <div className="aspect-w-5 aspect-h-3 -mt-6 md:aspect-w-2 md:aspect-h-1">
                        <img
                            className="translate-x-6 translate-y-6 transform rounded-md object-cover object-center sm:translate-x-16 lg:translate-y-20"
                            src={items[itemId].image}
                            alt="App screenshot"
                        />
                    </div>
                </div>
            </div>
        </div>
    </>
    return(
<div className="flex flex-col items-center">
    {menu3}
    {productGeneral}
</div>
    )
}