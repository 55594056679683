export default function Divider(props) {
    return (
        <div className="relative mt-[10vh] mb-[10vh]" id={props.id}>
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center">
                <span className="bg-white px-2 text-sm text-gray-500">{props.text}</span>
            </div>
        </div>
    )
}
