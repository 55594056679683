import {EnvelopeIcon} from "@heroicons/react/20/solid";

export default function Cenovanabidkabutton() {
    return(
        <a href="#kontakt">
        <button
            type="button"
            className="inline-flex items-center rounded-md border border-transparent bg-topklimatBlue px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
            <EnvelopeIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />cenová nabídka
        </button></a>
    )
}