import Cenovanabidkabutton from "./cenovanabidkabutton";

export default function Logodump() {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                <div className="lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
                    <div>
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                            Spolupracujeme se špičkami
                        </h2>
                        <p className="mt-3 max-w-3xl text-lg text-gray-500">
                            Dlouholeté spolupráce udržujeme s inovátory v širokém spektru odvětví. Od mezinárodních společností po České startupy, jsme pyšní na naše partnery.
                        </p>
                        <div className="mt-8 sm:flex">
                            <Cenovanabidkabutton />
                            <div className="mt-3 sm:mt-0 sm:ml-3">

                            </div>
                        </div>
                    </div>
                    <div className="mt-8 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-2 ">
                        <div className="col-span-1 flex justify-center bg-gray-50 py-8 px-8">
                            <img
                                className="max-h-12 h-fit"
                                src="https://zaloha.plasmaplace.cz/wp-content/uploads/2019/06/logo.svg"
                                alt="Workcation"
                            />
                        </div>
                        <div className="col-span-1 flex justify-center bg-gray-50 py-8 px-8">
                            <img className="max-h-12 h-fit" src="https://www.bb.cz/assets/cs/images/logo.png" alt="Mirage" />
                        </div>
                        <div className="col-span-1 flex justify-center bg-gray-50 py-8 px-8">
                            <img className="max-h-12 h-fit" src="https://www.occestlice.cz/app/uploads/2021/09/logo-mountfield-ai.png" alt="Tuple" />
                        </div>
                        <div className="col-span-1 flex justify-center bg-gray-50 py-8 px-8">
                            <img
                                className="max-h-12 h-fit"
                                src="https://www.alzheimerhome.cz/wp-content/uploads/2022/06/Logo_AH_Globalni_Penta_2022-svg.svg"
                                alt="Laravel"
                            />
                        </div>
                        <div className="col-span-1 flex justify-center bg-gray-50 py-8 px-8">
                            <img
                                className="max-h-12 h-fit"
                                src="https://www.natur.cuni.cz/fakulta/obrazky/fnmotol.png/image_full"
                                alt="StaticKit"
                            />
                        </div>
                        <div className="col-span-1 flex justify-center bg-gray-50 py-8 px-8">
                            <img
                                className="max-h-12"
                                src="https://www.coloseum.cz/wp-content/uploads/2022/06/coloseum-logo-obdelnik-pruhledne-1-3-300x213.png"
                                alt="Statamic"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
