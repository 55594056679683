/*
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/typography'),
    ],
  }
  ```
*/
export default function Klimatizace() {
    return (
        <div className="overflow-hidden bg-white py-16 px-4 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-max lg:max-w-7xl">
                <div className="relative z-10 mb-8 md:mb-2 md:px-6">
                    <div className="max-w-prose text-base lg:max-w-none">
                        <h2 className="font-semibold leading-6 text-orange-500">Co je to multisplit?</h2>
                        <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                            Multisplit, nebo split?
                        </h3>
                    </div>
                </div>
                <div className="relative">
                    <svg
                        className="absolute top-0 right-0 -mt-20 -mr-20 hidden md:block md:[overflow-anchor:none]"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                        aria-hidden="true"
                    >
                        <defs>
                            <pattern
                                id="95e8f2de-6d30-4b7e-8159-f791729db21b"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#95e8f2de-6d30-4b7e-8159-f791729db21b)" />
                    </svg>
                    <svg
                        className="absolute bottom-0 left-0 -mb-20 -ml-20 hidden md:block md:[overflow-anchor:none]"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                        aria-hidden="true"
                    >
                        <defs>
                            <pattern
                                id="7a00fe67-0343-4a3c-8e81-c145097a3ce0"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)" />
                    </svg>
                    <div className="relative md:bg-white md:p-6">
                        <div className="prose prose-indigo mx-auto lg:mx-0 mt-5 text-gray-500 lg:col-start-1 lg:row-start-1 lg:max-w-1/2">
                            <ul role="list" className="">
                                <li className="">                                   Zatímco Split systém se skládá z jedné venkovní a pouze jedné vnitřní jednotky, které jsou vzájemně propojeny rozvody chladiva, v případě Multi-Split systémů lze k jedné venkovní jednotce připojit podle potřeby až pět vnitřních jednotek.
                                </li>
                                <li>                                    Varianta Multi-Split je ideální pro více místností nebo pro vícezónové použití ve velkých místnostech. Hlavní výhodou jsou menší počet venkovních jednotek, úspora místa instalace a snadná montáž.
                                </li>
                            </ul>
                        </div>
                        <div className="lg:grid lg:grid-cols-2 lg:gap-6 mt-10">

                            <div className="prose prose-lg prose-indigo text-gray-500 lg:max-w-none">
                               <h4 className="">
                                   MultiSplit
                               </h4>
                                <p>Řešení pro více místností. Úspora prostoru díky jedné společné venkovní jednotce.</p>
                                <img
                                    alt="foto multisplit"
                                    className="lg:w-1/2 mx-auto my-auto"
                                    src="https://www.toshiba-aircondition.com/files/toshiba/images/default/produkte-multi-split.jpg"
                                />
                            </div>
                            <div className="prose prose-lg prose-indigo mt-6 text-gray-500 lg:mt-0">
                                <h4 className="">
                                    Split systém
                                </h4>
                                <p>Řešení pro jednu místnost. Optimální pro přesnou klimatizaci a nezávislý provoz.</p>
                                <img
                                    alt="foto singlesplit"
                                    className="lg:w-1/2 mx-auto my-auto"
                                src="https://www.toshiba-aircondition.com/files/toshiba/images/default/produkte-single-split.jpg"
                                />
                            </div>
                        </div>
                        <div className=" text-sm text-gray-500 mt-3"><p>Text Převzatý z <a href="https://www.toshiba-aircondition.com/cz/split-amulti-split.html" className="text-gray-700 underline">Toshiba</a></p> </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
