import { UsersIcon } from '@heroicons/react/24/outline'
import OnasFoto from "../images/general/topklimatonas.webp"
export default function Onasvic() {
    return (
        <div className="relative bg-white">
            <div className="h-56 sm:h-72 lg:absolute lg:left-0 lg:h-full lg:w-1/2 ">
                <img
                    className="h-full w-full object-cover overflow-hidden lg:rounded-md"
                    src={OnasFoto}
                    alt="Support team"
                />
            </div>
            <div className="relative mx-auto max-w-7xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16">
                <div className="mx-auto max-w-2xl lg:mr-0 lg:ml-auto lg:w-1/2 lg:max-w-none lg:pl-10">
                    <div>
                        <div className="flex h-12 w-12 items-center justify-center rounded-md bg-[#0096C8] text-white">
                            <UsersIcon className="h-6 w-6" aria-hidden="true" />
                        </div>
                    </div>
                    <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        O nás
                    </h2>
                    <p className="mt-6 text-lg text-gray-500">
                        Naše společnost byla založena v roce 2002 a za velmi krátkou dobu si vybudovala pevné místo na trhu. Zajišťujeme prodej, montáž a kompletní servis v oboru chladící a klimatizační techniky, zejména pak tepelných čerpadel, ventilací a solárních panelů pro rodinné domy, bytové domy (rekonstrukce kotelen, M a R) a komerční objekty.
                    </p>
                    <div className="mt-8 overflow-hidden">
                        <dl className="-mx-8 -mt-8 flex flex-wrap">
                            <div className="flex flex-col px-8 pt-8">
                                <dt className="order-2 text-base font-medium text-gray-500">let praxe</dt>
                                <dd className="order-1 text-2xl font-bold text-orange-500 sm:text-3xl sm:tracking-tight">20</dd>
                            </div>
                            <div className="flex flex-col px-8 pt-8">
                                <dt className="order-2 text-base font-medium text-gray-500">Montáží</dt>
                                <dd className="order-1 text-2xl font-bold text-orange-500 sm:text-3xl sm:tracking-tight">2000+</dd>
                            </div>
                            <div className="flex flex-col px-8 pt-8">
                                <dt className="order-2 text-base font-medium text-gray-500">Spokojených zákazníků</dt>
                                <dd className="order-1 text-2xl font-bold text-orange-500 sm:text-3xl sm:tracking-tight">Nespočet</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        </div>
    )
}
