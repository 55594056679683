export default function Landing(){
    return(
        <div className="flex flex-col w-full h-full justify-center h-full  items-center sm:mt-20 mb-10">
            <div className=" lg:rounded-lg h-[50vh] md:h-[70vh] lg:h-[80vh] w-screen md:w-[87vw] bg-skierbanner2 bg-cover bg-center flex flex-col justify-center lg:justify-end">
                <div className=" lg:h-1/2"></div>
                <div className=" rounded-md bg-white w-full lg:w-fit flex flex-col text-5xl lg:text-9xl font-bold pl-5 pt-20 pb-20 md:p-10 lg:ml-10 lg:mb-10 mix-blend-screen mix-blend-screen">
                    <div className="">Inovace v</div>
                    <div className="">Oblasti chlazení.</div>

                </div>
            </div>
        </div>
    )
}