import TopklimatLogo from "../images/topklimatlogo.svg"
export default function Error404() {
    return (
        <>
            {/*
        This example requires updating your template:

        ```
        <html class="h-full">
        <body class="h-full">
        ```
      */}
            <div className="flex min-h-full flex-col bg-white pt-16 pb-12">
                <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
                    <div className="flex flex-shrink-0 justify-center">
                        <a href="/" className="inline-flex">
                            <span className="sr-only">Topklimat logo</span>
                            <img
                                className="h-12 w-auto"
                                src={TopklimatLogo}
                                alt=""
                            />
                        </a>
                    </div>
                    <div className="py-16">
                        <div className="text-center">
                            <p className="text-base font-semibold text-topklimatBlue">404</p>
                            <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Stránka nenalezena</h1>
                            <p className="mt-2 text-base text-gray-500">Bohužel jsme na této adrese nic nenašli.</p>
                            <div className="mt-6">
                                <a href="https://www.topklimat.cz" className="text-base font-medium text-topklimatBlue hover:text-indigo-500">
                                    Vraťte se zpět
                                    <span aria-hidden="true"> &rarr;</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}
