

export default function Footer() {
    return (
        <footer className="bg-topklimatBlue ">
            <div className="mx-auto max-w-7xl overflow-hidden py-12 px-4 sm:px-6 lg:px-8">
                <p className="text-center text-base text-white"><a href="https://www.topklimat.cz/vasedatanamnejsouukradena" >Ochrana odobních údajů</a></p>
                <p className="mt-8 text-center text-base text-white">&copy; 2022 Topklimat spol s.r.o. Všechna práva vyhrazena.</p>
                <p className="mt-8 text-center text-base text-white">Design & Code od <a href="https://www.deleno.cz" className="hover:text-orange-500">dělena</a></p>

            </div>
        </footer>
    )
}
